.chat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #4d4d4d;
  border-radius: 50%;
  animation: pulse 2s infinite;
  cursor: pointer;
  padding: 10px;
}

.chat-icon:hover {
  animation: none;
  background-color: #161616;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(77, 77, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(77, 77, 255, 0);
  }
}

.chat-text p {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.chatbot-widget {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 279px;
  background-color: #ffffffe0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 9999;
}

.chat-answer p {
  color: #000;
  font-weight: 300;
}

.show-chatbot-widget {
  display: block;
}

.chat-history {
  height: 200px;
  overflow-y: scroll;
  padding: 10px;
}

.chat-message p {
  margin-bottom: 10px;
}

.chat-question {
  font-weight: bold;
}

.prompts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.prompt-button {
  width: 48%;
  margin-bottom: 10px;
  background-color: #e6e6e6;
  color: #000000;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9rem;
}

.prompt-button:hover {
  background-color: #818181;
}
