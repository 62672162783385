body.dark-mode {
  background-color: #202020;
  color: #f6f7f8;
}

body.dark-mode::after {
  background-color: #202020;
  color: #f6f7f8;
  background-image: url(/public/images/black_header.png);
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 768px) {
  body.dark-mode::after {
    background-size: cover;
  }
}

.dark-mode header.active {
  background: rgba(51, 51, 51, 0.8);
}

.dark-mode header .heIcon {
  color: #f6f7f8;
}

.dark-mode header .toggle .close {
  background: #2b2b2b;
}

.dark-mode header .center .mobile-nav {
  background: #202020e8;
  color: #202020;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.dark-mode header .center .mobile-nav li a:hover {
  color: #d61010;
}

.dark-mode header .center .mobile-nav li a {
  color: #f6f7f8;
}

.dark-mode header .center .mobile-nav li {
  transition: color 0.5s ease-in-out;
  margin-top: 25px;
}

.dark-mode .menu a {
  color: #f6f7f8;
}

.dark-mode a:hover {
  color: #5c5c5c;
  transition: 0.5s ease-in-out;
}

.dark-mode .hero p {
  color: #f6f7f8;
}

.dark-mode .hero .search {
  background: #555;
  color: #f6f7f8;
  box-shadow: rgba(0, 0, 0, 0.05) 1.95px 1.95px 2.6px;
}

.dark-mode .hero .search hr {
  background: rgba(51, 51, 51, 0.8);
}

.dark-mode .right {
  color: #202020;
}

.darkmode .active {
  color: #d61710;
}

.dark-mode .product {
  background-image: url(/public/images/black-spray-paint-black.png);
}

.dark-mode .container .heading h2 {
  color: #f6f7f8;
}

.dark-mode .container .heading p {
  color: #f6f7f8;
}

.dark-mode .modal-content {
  background-color: #5c5c5c;
}

.dark-mode .close {
  color: #f6f7f8;
}

.dark-mode .modal-content {
  box-shadow: rgba(250, 250, 250, 0.05) 1.95px 1.95px 2.6px;
}

.dark-mode .contact-form form {
  background-color: #202020b7;
}

.dark-mode .iframe {
  background-color: #202020b7;
}

.dark-mode .chatbot-widget {
  background-color: #424242;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.dark-mode .chat-icon:hover {
  background-color: #818181;
}

.dark-mode .chat-message p {
  color: #fff;
}

.dark-mode .itemCards .card {
  background: rgba(133, 133, 133, 0.8);
}
